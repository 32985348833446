<template>
  <v-card class="pa-0 ma-0" :disabled="loading" elevation="0">
    <v-row>
      <v-col cols="12">
        <lb-string v-model="formdata['Vendor Name']" label="Vendor Name" :error="uploaderror['Vendor Name']"/>
      </v-col>
      <v-col cols="4">
        <lb-string v-model="formdata['Vendor Code']" label="Vendor Code" :error="uploaderror['Vendor Code']"/>
      </v-col>
      <v-col cols="4">
        <lb-string v-model="formdata['PAN']" label="PAN" :error="uploaderror['PAN']"/>
      </v-col>
      <v-col cols="4">
        <lb-dropdown :items="['Goods','Service']" label="Goods/Services provider" v-model="formdata['Goods/Services provider']" :error="uploaderror['Goods/Services provider']"/>
      </v-col>
      <v-col cols="3">
        <lb-dropdown :items="['Yes','No']" label="LDC" v-model="formdata['LDC']" :error="uploaderror['LDC']"/>
      </v-col>
      <v-col cols="3">
        <lb-string v-model="formdata['LDC Number']" label="LDC Number" :error="uploaderror['LDC Number']"/>
      </v-col>
      <v-col cols="3">
        <lb-number v-model="formdata['LDC Rate']" label="LDC Rate" :error="uploaderror['LDC Rate']"/>
      </v-col>
      <v-col cols="3">
        <lb-date v-model="formdata['LDC Start date']" label="LDC Start Date" :error="uploaderror['LDC Start date']"/>
      </v-col>
      <v-col cols="3">
        <lb-date v-model="formdata['LDC End date']" label="LDC End Date" :error="uploaderror['LDC End date']"/>
      </v-col>
      <v-col cols="3">
        <lb-number v-model="formdata['LDC Remaining balance']" label="LDC Remaining balance" :error="uploaderror['LDC Remaining balance']"/>
      </v-col>
      <v-col cols="3">
        <lb-number v-model="formdata['LDC Threshold']" label="LDC Threshold" :error="uploaderror['LDC Threshold']"/>
      </v-col>
      <v-col cols="3">
        <lb-number v-model="formdata['LDC Utilized YTD']" label="LDC Utilized YTD" :error="uploaderror['LDC Utilized YTD']"/>
      </v-col>
      <v-col cols="3">
        <lb-number v-model="formdata['Opening provision rate']" label="Opening provision rate" :error="uploaderror['Opening provision rate']"/>
      </v-col>
      <v-col cols="3">
        <lb-number v-model="formdata['Opening provision balance']" label="Opening provision balance" :error="uploaderror['Opening provision balance']"/>
      </v-col>
      <v-col cols="3">
        <lb-number v-model="formdata['Opening Provision utilized YTD']" label="Opening Provision utilized YTD" :error="uploaderror['Opening Provision utilized YTD']"/>
      </v-col>
      <v-col cols="3">
        <lb-number v-model="formdata['Opening Provision remaining balance']" label="Opening Provision remaining balance" :error="uploaderror['Opening Provision remaining balance']"/>
      </v-col>
      <v-col cols="3">
        <lb-dropdown :items="['194J(a)','194J(b)','194A','194I(a)','194I(b)','194C','194Q','194H','195','206','194R','Equalisation Levy','N.A']" label="TDS Section" v-model="formdata['TDS Section']" :error="uploaderror['TDS Section']"/>
      </v-col>
      <v-col cols="3">
        <lb-number v-model="formdata['TDS Rate']" label="TDS Rate" :error="uploaderror['TDS Rate']"/>
      </v-col>
      <v-col cols="3">
        <lb-dropdown :items="['Yes','No']" label="Threshold" v-model="formdata['Threshold']" :error="uploaderror['Threshold']"/>
      </v-col>
      <v-col cols="3">
        <lb-number v-model="formdata['Threshold Amount']" label="Threshold Amount" :error="uploaderror['Threshold Amount']"/>
      </v-col>
      <v-col cols="6">
        <lb-dropdown :items="['Yes','No']" label="Specified person under 206AB/CCA" v-model="formdata['Specified person under 206AB/CCA']" :error="uploaderror['Specified person under 206AB/CCA']"/>
      </v-col>
      <v-col cols="6">
        <lb-number v-model="formdata['YTD Transactions']" label="YTD Transactions" :error="uploaderror['YTD Transactions']"/>
      </v-col>
    </v-row>
    <v-card-text class="px-0 text-right">
      <v-btn color="primary" elevation="0" @click="addEdit()" :loading="loading">
        <v-icon class="mr-1">mdi-plus</v-icon>
        {{existingid?'Update':'Add'}}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'tds_vm_form',
  props: ['data'],
  data: function(){
    return {
      formdata: {},
      loading: false,
      existingid: "",
      uploaderror: {},
    }
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  mounted() {
  },
  methods: {
    refreshData() {
      this.formdata = {};
      this.loading = false;
      this.existingid = "";
      this.setData();
    },
    setData() {
      this.loading = true;
      if(this.data._id) this.existingid = this.data._id;
      this.formdata["Vendor Code"] = this.data["Vendor Code"] || "";
      this.formdata["Vendor Name"] = this.data["Vendor Name"] || "";
      this.formdata["PAN"] = this.data["PAN"] || "";
      this.formdata["Goods/Service"] = this.data["Goods/Service"] || "";
      this.formdata["LDC"] = this.data["LDC"] || "No";
      this.formdata["LDC Number"] = this.data["LDC Number"] || 0;
      this.formdata["LDC Rate"] = this.data["LDC Rate"] || "";
      this.formdata["LDC Remaining balance"] = this.data["LDC Remaining balance"] || 0;
      this.formdata["LDC Threshold"] = this.data["LDC Threshold"] || 0;
      this.formdata["LDC Utilized YTD"] = this.data["LDC Utilized YTD"] || 0;
      this.formdata["Opening Provision remaining balance"] = this.data["Opening Provision remaining balance"] || 0;
      this.formdata["Opening Provision utilized YTD"] = this.data["Opening Provision utilized YTD"] || 0;
      this.formdata["Opening provision balance"] = this.data["Opening provision balance"] || 0;
      this.formdata["Opening provision rate"] = this.data["Opening provision rate"] || 0;
      this.formdata["Specified person under 206AB/CCA"] = this.data["Specified person under 206AB/CCA"] || "";
      this.formdata["TDS Rate"] = this.data["TDS Rate"] || "";
      this.formdata["TDS Section"] = this.data["TDS Section"] || "";
      this.formdata["Threshold"] = this.data["Threshold"] || "";
      this.formdata["Threshold Amount"] = this.data["Threshold Amount"] || 0;
      this.formdata["YTD Transactions"] = this.data["YTD Transactions"] || 0;
      this.loading = false;
    },
    addEdit: function(){
      this.loading = true;
      this.uploaderror = {};
      let link = "/v2/tds/vm/add";
      if(this.existingid) link = "/v2/tds/vm/edit";
      this.axios.post(link, {data: [{client: this.$store.state.client ,data: [this.formdata]}]}).then(dt => {
        if(dt.data.status === "success"){
          if(this.existingid) this.$emit('vmdoneedit');
          else this.$emit('vmdoneadd');
        }else{
          this.uploaderror = (dt.data.data[0] || {}).index0 || {};
          throw new dt.data.message || "Error uploading data";
        }
      }).catch(err => {
        console.log(err);
        this.$store.commit("sbError", err.message || err);
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>
